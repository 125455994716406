import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'

export default class FaqCategory extends Model {
    endpoint = 'faq_categories'

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name_es', 'Nombre en español'),
      new Field('name_en', 'Nombre en inglés'),
      new Field('order', 'Orden', 'number'),
    ]

    clone = () => FaqCategory
}
